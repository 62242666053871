
import AppMixin from '~/mixins/App'
import envMixin from "@/mixins/envMixin";
export default {
  name: 'ConversationIndex',
  mixins: [AppMixin, envMixin],

  methods: {
    goTo(url) {
      if (!url) {
        return
      }

      window.open(url, '_blank')
    },
  },
}
